<template>
	<div class="ion-padding frage">
		<span>NUR EINE ANTWORT STIMMT</span>
		<h1 class="slabs">{{ module.frage }}</h1>
	</div>
	<ion-list>
		<ion-item v-for="(answer, index) in answersOptions" :key="answer">
			<ion-label class="ion-text-wrap">{{ answer.name }}</ion-label>
			<ion-checkbox
				slot="start"
				:value="answer.name"
				mode="ios"
				:name="answer.name"
				:checked="answer.isChecked"
				@ionChange="changeVal(index)"
			></ion-checkbox>
		</ion-item>
	</ion-list>
</template>

<script>
import { IonList, IonItem, IonCheckbox, IonLabel } from '@ionic/vue';
export default {
	props: {
		moduleContent: Object,
	},
	components: {
		IonList,
		IonItem,
		IonCheckbox,
		IonLabel,
	},
	data() {
		return {
			multiple: '',
			answersOptions: [],
			module: {},
		};
	},
	watch: {
		moduleContent: {
			handler() {
				this.module = JSON.parse(JSON.stringify(this.moduleContent));
				this.answersOptions = [];
				this.module.optionen.forEach((answ) =>
					this.answersOptions.push({ name: answ, isChecked: false })
				);
				this.shuffleArray(this.answersOptions)
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		changeVal(index) {
			const newArr = JSON.parse(JSON.stringify(this.answersOptions));
			for (let i = 0; i < newArr.length; i++) {
				if (i === index) newArr[i]['isChecked'] = true;
				else newArr[i]['isChecked'] = false;
			}
			this.answersOptions = newArr;
		},
		async validate() {
			console.log('validate multiple');
			const answer = this.answersOptions.find(
				(ans) => ans.name === this.moduleContent.correct
			);
			if (answer?.isChecked) return true;
			else return false;
		},
		shuffleArray(arr) {
			for (let i = arr.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[arr[i], arr[j]] = [arr[j], arr[i]];
			}
			return arr;
		},
	},
};
</script>

<style lang="scss" scoped>
.frage {
	padding-top: 60px;
	span {
		color: var(--ion-color-medium);
		font-size: 13px;
	}
	h1 {
		font-size: 20px;
		margin-top: 10px;
	}
	border-bottom: 0.55px solid #c8c7cc;
}
</style>
