<template>
	<div id="greeting" :style="`background-image: url(${imgLink})`"></div>
	<span class="texttag" v-if="advertorial">GESPONSERT</span>
	<div v-html="moduleContent.txt" class="ion-padding wysiwig"></div>
</template>

<script>
export default {
	props: {
		moduleContent: Object,
		advertorial: Boolean,
	},
	computed: {
		width() {
			return window.innerWidth;
		},
		imgLink() {
			if (!this.moduleContent.img) return;

			let screen = 'm';
			if (this.width >= 768) screen = 'lg';
			else if (this.width >= 1025) screen = 'xl';
			return `${this.$store.state.imageBase}${
				this.moduleContent.img
			}?key=textmodule-${screen}&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
	},
};
</script>

<style lang="scss">
#greeting {
	height: 300px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	margin-bottom: 0px;
}

@media screen and (min-width: 1025px) {
	#greeting {
		height: 350px;
	}
}

.wysiwig {
	margin-top: 5px;

	h2 {
		font-family: 'Milo Slab', 'Helvetica Neue', 'Roboto', sans-serif;
		font-weight: bold;
		font-size: 18px;
	}
}

.texttag {
	position: absolute;
	right: 0;
	padding: 0.2rem;
	background-color: var(--ion-color-tertiary);
	color: var(--ion-color-dark);
	display: inline;
	padding-left: 2px;
	padding-right: 2px;
}
</style>
