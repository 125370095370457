<template>
	<div id="second">
		<div class="box">
			<div class="stars animate__animated animate__rubberBand">
				<ion-img
					class="animate__animated animate__rotateIn"
					:src="require('../../assets/img/stern2.svg')"
				></ion-img>
				<ion-img
					class="center animate__animated animate__rotateIn"
					:src="require('../../assets/img/stern2.svg')"
				></ion-img>
				<ion-img
					class="animate__animated animate__rotateIn"
					:src="require('../../assets/img/stern2.svg')"
				></ion-img>
			</div>

			<p>
				Glückwunsch, {{ $store.state.user.first_name }}!
				<br />
				Du hast folgendes Kapitel erfolgreich abgeschlossen:
			</p>
			<p class="last">{{ name }}</p>
			<ion-button color="primary" @click="dismissModal(false)"
				>Zum nächsten Kapitel</ion-button
			>
			<ion-button fill="outline" color="black" @click="dismissModal(true)"
				>Zurück zur Kursübersicht</ion-button
			>
		</div>
	</div>
</template>

<script>
import { IonButton, modalController, IonImg } from '@ionic/vue';
export default {
	components: {
		IonButton,
		IonImg,
	},
	props: {
		name: String,
	},
	methods: {
		dismissModal(kill) {
			modalController.dismiss(kill);
		},
	},
};
</script>

<style lang="scss" scoped>
#second {
	background-color: var(--ion-color-light);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: url(/assets/img/successbg.jpg);
	background-position: center center;
	background-size: cover;

	.box {
		width: 350px;
		max-width: 80vw;
		margin-top: -50px;
		background-color: white;
		padding: 20px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
		display: flex;
		flex-direction: column;
		text-align: center;

		.stars {
			display: flex;
			justify-content: center;
			align-content: center;
			ion-img {
				height: 3rem;
				margin: 2.5rem 0 0.5rem 0;
				&.center {
					height: 4.5rem;
					margin: 1rem 0 0.5rem 0;
				}
			}
		}

		p {
			color: var(--ion-color-dark);
			&.last {
				margin-bottom: 25px;
			}
		}
	}
}
</style>
