<template>
	<div class="ion-padding frage">
		<span v-if="module.correct.length > 1"
			>FINDE DIE {{ module.correct.length }} RICHTIGEN BILDER</span
		>
		<span v-else>FINDE DAS RICHTIGE BILD</span>
		<h1 class="slabs">{{ module.frage }}</h1>
	</div>
	<div class="container">
		<div id="images">
			<div
				class="image"
				:class="{ selected: selectedImg(img) }"
				v-for="img in module.images"
				:key="img"
			>
				<img :src="imgLink(img)" @click="selectImg(img)" />
				<ion-img
					class="icon"
					:src="require('../../assets/img/selected.svg')"
					v-if="selectedImg(img)"
				></ion-img>
			</div>
		</div>
	</div>
</template>

<script>
import { IonImg } from '@ionic/vue';

export default {
	props: {
		moduleContent: Object,
	},
	components: {
		IonImg,
	},
	data() {
		return {
			selected: [],
		};
	},
	watch: {
		moduleContent: {
			handler() {
				this.module = JSON.parse(JSON.stringify(this.moduleContent));
				this.module.images = this.shuffleArray(this.module.images);
				this.selected = [];
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		async validate() {
			console.log('validate images');

			// Have all right answers
			const result = this.moduleContent.correct.every((ans) => {
				if (this.selected.includes(ans)) return true;
				return false;
			});

			// Don't have wrong answers
			const result2 = this.selected.every((ans) => {
				if (!this.moduleContent.correct.includes(ans)) return false;
				return true;
			});

			return result && result2;
		},
		imgLink(img) {
			if (!img) return;

			return `${
				this.$store.state.imageBase
			}${img}?key=images-sq&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
		selectedImg(img) {
			return this.selected.includes(img);
		},
		selectImg(img) {
			const corrIndex = this.selected.indexOf(img);
			if (corrIndex > -1) this.selected.splice(corrIndex, 1);
			else this.selected.push(img);
		},
		shuffleArray(arr) {
			for (let i = arr.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[arr[i], arr[j]] = [arr[j], arr[i]];
			}
			return arr;
		},
	},
};
</script>

<style lang="scss" scoped>
.frage {
	padding-top: 60px;
	span {
		color: var(--ion-color-medium);
		font-size: 13px;
	}
	h1 {
		font-size: 20px;
		margin-top: 10px;
	}
	border-bottom: 0.55px solid #c8c7cc;
}

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

#images {
	display: grid;
	column-gap: 0.3rem;
	row-gap: 0.3rem;
	grid-template-columns: repeat(2, 170px);
	width: auto;

	.image {
		position: relative;
		width: 170px;
		height: 170px;

		&.selected {
			border: 3px solid;
			border-color: var(--ion-color-primary);
			box-shadow: 0px 5px 12px 1px #c8c7cc;
		}
	}
	.icon {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 4px;
		right: 4px;
	}
}

@media screen and (min-width: 768px) {
	#images {
		grid-template-columns: repeat(2, 200px);
		.image {
			width: 200px;
			height: 200px;
		}
	}
}
</style>
