<template>
	<div id="resultmodal">
		<div class="frame">
			<ion-img
				class="icon animate__animated animate__tada"
				:src="require('../../assets/img/richtig.svg')"
				v-if="type === 'success'"
			></ion-img>
			<ion-img
				class="icon animate__animated animate__headShake"
				:src="require('../../assets/img/falsch.svg')"
				v-else
			></ion-img>
			<div v-html="message" class="content ion-padding"></div>
			<ion-button @click="$emit('onOK')" class="ion-padding"
				>OK</ion-button
			>
		</div>
	</div>
</template>

<script>
import { IonButton, IonImg } from '@ionic/vue';
export default {
	props: {
		name: String,
		type: String,
		reasonAnswers: String,
		correctAnswers: String,
	},
	components: {
		IonButton,
		IonImg,
	},
	computed: {
		message() {
			if (this.type === 'failure')
				return `Das hat leider nicht gestimmt! ${this.correctAnswers}`;
			else if (this.type === 'success' && this.reasonAnswers !== '')
				return `Das war richtig, ${this.name}! ${this.reasonAnswers}`;
			else return `Das war richtig, ${this.name}!`;
		},
	},
};
</script>

<style lang="scss" scoped>
#resultmodal {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 110;

	.frame {
		background-color: white;
		width: 50%;
		min-width: 300px;
		height: auto;
		text-align: center;
		opacity: 1;
		box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
		padding: 1.5rem 0.5rem;
		margin-top: -2rem;

		ion-button::part(native) {
			padding: 1rem 1.2rem;
		}

		.button {
			margin-bottom: 1.5rem;
		}

		.icon {
			height: 70px;
		}

		.content {
			color: var(--ion-text-color);
			text-align: center;
		}
	}
}
</style>
