<template>
	<div id="second">
		<div class="box">
			<p>Glückwunsch, {{ $store.state.user.first_name }}!</p>
			<p>
				Du hast alle Kapitel von {{name}} erfolgreich abgeschlossen.
			</p>
			<ion-button color="primary" @click="dismissModal(false)"
				>Auszeichnung abholen</ion-button
			>
		</div>
	</div>
</template>

<script>
import { IonButton, modalController } from '@ionic/vue';
export default {
	props: {
		name: String,
	},
	components: {
		IonButton,
	},
	methods: {
		dismissModal(kill) {
			modalController.dismiss(kill);
		},
	},
};
</script>

<style lang="scss" scoped>
#second {
	background-color: var(--ion-color-light);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
		background:	url(/assets/img/successbg.jpg);
	background-position: center center;
	background-size: cover;

	.box {
		width: 350px;
		max-width: 80vw;
		margin-top: -50px;
		background-color: white;
		padding: 20px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
		display: flex;
		flex-direction: column;
		text-align: center;

		p {
			color: var(--ion-color-dark);
		}
	}
}
</style>
