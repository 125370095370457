<template>
	<div id="badge">
		<div class="box">
			<p>Du bist jetzt:</p>
			<h2 class="slabs">
				{{ name }}
			</h2>
			<ion-button fill="outline" color="black" @click="dismissModal(true)">
				Zur Kurs-Übersicht
			</ion-button>
			<ion-avatar
				class="image animate__animated animate__flip animate__bounce"
			>
				<img :src="imgLink" />
			</ion-avatar>
		</div>
	</div>
</template>

<script>
import { IonButton, IonAvatar, modalController } from '@ionic/vue';
export default {
	props: {
		name: String,
		icon: String,
	},
	components: {
		IonButton,
		IonAvatar,
	},
	computed: {
		imgLink() {
			return `${this.$store.state.imageBase}${
				this.icon
			}?key=kursicon&access_token=${localStorage.getItem('auth_token')}`;
		},
	},
	methods: {
		dismissModal(kill) {
			modalController.dismiss(kill);
		},
	},
};
</script>

<style lang="scss" scoped>
#badge {
	background-color: var(--ion-color-light);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: url(/assets/img/badgebg.jpg);
	background-position: center center;
	background-size: cover;

	.box {
		width: 350px;
		max-width: 80vw;
		margin-top: -50px;
		background-color: white;
		padding: 20px;
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
		display: flex;
		flex-direction: column;
		text-align: center;
		position: relative;

		h2 {
			font-weight: bold;
			margin-bottom: 2rem;
			color: var(--ion-color-black);
		}

		p {
			color: var(--ion-color-dark);
			margin-top: 3rem;
			margin-bottom: 0;
		}

		ion-avatar {
			position: absolute;
			top: -30px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 75px;
			height: 75px;
		}
	}
}
</style>
