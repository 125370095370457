<template>
	<div class="ion-padding frage">
		<span>WAHR ODER FALSCH?</span>
		<h1 class="slabs">{{ module.frage }}</h1>
	</div>
	<ion-list>
		<ion-item v-for="(answer, index) in answers" :key="answer">
			<ion-label>{{ answer.name }}</ion-label>
			<ion-checkbox
				mode="ios"
				slot="start"
				:value="answer.name"
				:name="answer.name"
				:checked="answer.isChecked"
				@ionChange="changeVal(index)"
			></ion-checkbox>
		</ion-item>
	</ion-list>
</template>

<script>
import { IonList, IonItem, IonLabel, IonCheckbox } from '@ionic/vue';
export default {
	props: {
		moduleContent: Object,
	},
	components: {
		IonList,
		IonItem,
		IonLabel,
		IonCheckbox,
	},
	watch: {
		moduleContent: {
			handler() {
				console.log('new module');
				this.module = JSON.parse(JSON.stringify(this.moduleContent));
				this.answers = [
					{
						name: 'Ja',
						isChecked: false,
					},
					{
						name: 'Nein',
						isChecked: false,
					},
				];
			},
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			module: {},
			answers: [
				{
					name: 'Ja',
					isChecked: false,
				},
				{
					name: 'Nein',
					isChecked: false,
				},
			],
		};
	},
	methods: {
		changeVal(index) {
			const newArr = JSON.parse(JSON.stringify(this.answers));
			for (let i = 0; i < newArr.length; i++) {
				if (i === index) newArr[i]['isChecked'] = true;
				else newArr[i]['isChecked'] = false;
			}
			this.answers = newArr;
		},
		async validate() {
			console.log('validate janein');
			const answer = this.answers.find(
				(ans) => ans.name.toLowerCase() === this.module.correct
			);
			if (answer?.isChecked) return true;
			else return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.frage {
	padding-top: 60px;
	span {
		color: var(--ion-color-medium);
		font-size: 13px;
	}
	h1 {
		font-size: 20px;
		margin-top: 10px;
	}
	border-bottom: 0.55px solid #c8c7cc;
}
</style>
