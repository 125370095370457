<template>
	<div class="ion-padding frage">
		<span v-if="module.correct.length > 1"
			>FINDE DIE {{ module.correct.length }} RICHTIGEN AUSSAGEN</span
		>
		<span v-else>FINDE DIE RICHTIGE AUSSAGE</span>
		<h1 class="slabs">{{ module.frage }}</h1>
	</div>
	<ion-list>
		<ion-item v-for="answer in module.optionen" :key="answer">
			<ion-label class="ion-text-wrap">{{ answer }}</ion-label>
			<ion-checkbox
				slot="start"
				:value="answer"
				v-model="selected[answer]"
				mode="ios"
			></ion-checkbox>
		</ion-item>
	</ion-list>
</template>

<script>
import { IonList, IonItem, IonCheckbox, IonLabel } from '@ionic/vue';
export default {
	props: {
		moduleContent: Object,
	},
	components: {
		IonList,
		IonItem,
		IonCheckbox,
		IonLabel,
	},
	data() {
		return {
			selected: {},
			module: {},
		};
	},
	watch: {
		moduleContent: {
			handler() {
				this.module = JSON.parse(JSON.stringify(this.moduleContent));
				this.module.optionen = this.shuffleArray(this.module.optionen);
				this.selected = {};
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		async validate() {
			console.log('validate answers');

			// Have all right answers
			const result = this.moduleContent.correct.every((ans) => {
				if (!this.selected[ans]) return false;
				else return true;
			});

			// Don't have wrong answers
			const result2 = Object.entries(this.selected).every((ans) => {
				if (ans[1] && !this.moduleContent.correct.includes(ans[0]))
					return false;
				else return true;
			});

			return result && result2;
		},
		shuffleArray(arr) {
			for (let i = arr.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[arr[i], arr[j]] = [arr[j], arr[i]];
			}
			return arr;
		},
	},
};
</script>

<style lang="scss" scoped>
.frage {
	padding-top: 60px;
	span {
		color: var(--ion-color-medium);
		font-size: 13px;
	}
	h1 {
		font-size: 20px;
		margin-top: 10px;
	}
	border-bottom: 0.55px solid #c8c7cc;
}
</style>
