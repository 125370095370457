<template>
	<div id="statement">
		<div class="ion-padding frage">
			<h1 class="slabs">Welche Aussage stimmt?</h1>
		</div>

		<div class="radio-btn">
			<div class="label" :for="returnKeyOfIndex(module.options, 0)" :class="{ checked: choice === returnKeyOfIndex(module.options, 0) }" @click="choice = returnKeyOfIndex(module.options, 0)">{{
				module.options[0][returnKeyOfIndex(module.options, 0)]
			}}</div>
			<span>ODER</span>
			<div class="label" :for="returnKeyOfIndex(module.options, 1)" :class="{ checked: choice === returnKeyOfIndex(module.options, 1) }" @click="choice = returnKeyOfIndex(module.options, 1)">{{
				module.options[1][returnKeyOfIndex(module.options, 1)]
			}}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		moduleContent: Object,
	},
	watch: {
		moduleContent: {
			handler() {
				console.log('new module statement');
				const content = JSON.parse(JSON.stringify(this.moduleContent));
				this.module.options = this.shuffleArray([{"one": content.one}, {"two": content.two}])
				this.module.correct = content.correct
				this.choice = null;
			},
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			choice: null,
			module: {
				correct: String,
				options: Array
			}
		};
	},
	methods: {
		async validate() {
			console.log('validate statement');
			return this.choice === this.module.correct;
		},
		returnKeyOfIndex(object, index) {
			return Object.keys(object[index])[0]
		},
		shuffleArray(arr) {
			for (let i = arr.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[arr[i], arr[j]] = [arr[j], arr[i]];
			}
			return arr;
		},
	},
};
</script>

<style lang="scss" scoped>
#statement {
	.frage {
		padding-top: 60px;
		span {
			color: var(--ion-color-medium);
			font-size: 13px;
		}
		h1 {
			font-size: 20px;
			margin-top: 10px;
		}
		border-bottom: 0.55px solid #c8c7cc;
	}
	.radio-btn {
		width: 100%;
		display: flex;
		padding: 1rem;
		justify-content: center;
		flex-wrap: wrap;
	}
	.label {
		border: 1px solid var(--ion-color-light);
		padding: 1rem;
		width: 100%;
		cursor: pointer;
		color: var(--ion-color-dark);
		line-height: 1.3;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.249508);

		&.checked {
			border-color: var(--ion-color-primary);
		}
	}
	input[type='radio'] {
		display: none;
	}
	span {
		color: var(--ion-color-medium);
		margin: 1.5rem 0;
		font-size: 14px;
	}
}
</style>
